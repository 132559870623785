<template>
  <div>
    <div class="head">
      <div class="box">
        <div>
          <div class="title"><img src="../assets/img/bbgf.png"> 帮帮共富</div>
          <ul>
            <li class="on">首页</li>
            <li @click="rcClick">公司</li>
            <li @click="rwClick">任务</li>
            <li>联系我们</li>
          </ul>
        </div>
        <!-- <div>
          <input type="text"
                 placeholder="账号">
          <input type="text"
                 placeholder="密码">
          <p>登录</p>
          <p>注册</p>
        </div> -->
      </div>
    </div>
    <div style="height:51px"></div>
    <div class="lunbo">
      <swiper :options="swiperOption">
        <swiper-slide>
          <van-image width="100%"
                     height="100%"
                     fit="contain"
                     class="box1-img"
                     :src="'https://img0.baidu.com/it/u=28156331,373907367&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=333'" />
          <h2>
            <span class="c-3">活动活动</span>
          </h2>
        </swiper-slide>
        <swiper-slide>
          <van-image width="100%"
                     height="100%"
                     fit="cover"
                     class="box1-img"
                     :src="'https://img0.baidu.com/it/u=28156331,373907367&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=333'" />
          <h2>
            <span class="c-3">活动活动</span>
          </h2>
        </swiper-slide>
        <swiper-slide>
          <van-image width="100%"
                     height="100%"
                     fit="cover"
                     class="box1-img"
                     :src="'https://img2.baidu.com/it/u=4204542677,3987994467&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1656694800&t=be9ac1233d063780e1c6512f2eec08e6'" />
          <h2>
            <span class="c-3">活动活动</span>
          </h2>
        </swiper-slide>
        <div class="swiper-button-prev"
             slot="button-prev"></div>
        <div class="swiper-button-next"
             slot="button-next"></div>
        <div class="swiper-pagination"
             slot="pagination"></div>
      </swiper>
    </div>
    <h1 class="title1">任务列表</h1>
    <ul class="content">
      <li>
        <div class="img">
          <van-image width="100%"
                     height="100%"
                     fit="cover"
                     class="box1-img"
                     :src="'https://img2.baidu.com/it/u=4204542677,3987994467&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1656694800&t=be9ac1233d063780e1c6512f2eec08e6'" />
        </div>
        <dl>
          <dt>衣领制作 <span>人才自取</span></dt>
          <dd>需要阵脚密封，需要阵脚密封</dd>
        </dl>
        <div class="box">
          <p>浙江省</p>
          <p class="an"
             @click="jump">查看详情</p>
        </div>
      </li>
      <li>
        <div class="img">
          <van-image width="100%"
                     height="100%"
                     fit="cover"
                     class="box1-img"
                     :src="'http://t13.baidu.com/it/u=1426776976,2517687540&fm=224&app=112&f=JPEG?w=500&h=500'" />
        </div>
        <dl>
          <dt>纽扣制作 <span>人才自取</span></dt>
          <dd>需要仔细，不可以有太多损耗</dd>
        </dl>
        <div class="box">
          <p>浙江省</p>
          <p class="an"
             @click="jump">查看详情</p>
        </div>
      </li>
      <li>
        <div class="img">
          <van-image width="100%"
                     height="100%"
                     fit="cover"
                     class="box1-img"
                     :src="'https://img0.baidu.com/it/u=2017493137,2769633727&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=375'" />
        </div>
        <dl>
          <dt>机器按钮 <span>人才自取</span></dt>
          <dd>需要大量人才</dd>
        </dl>
        <div class="box">
          <p>台州市</p>
          <p class="an"
             @click="jump">查看详情</p>
        </div>
      </li>
      <li>
        <div class="img">
          <van-image width="100%"
                     height="100%"
                     fit="cover"
                     class="box1-img"
                     :src="'https://img0.baidu.com/it/u=4077685616,688691936&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500'" />
        </div>
        <dl>
          <dt>自行车配件 <span>人才自取</span></dt>
          <dd>有这方面经验的优先</dd>
        </dl>
        <div class="box">
          <p>浙江省</p>
          <p class="an"
             @click="jump">查看详情</p>
        </div>
      </li>
      <li>
        <div class="img">
          <van-image width="100%"
                     height="100%"
                     fit="cover"
                     class="box1-img"
                     :src="'https://img1.baidu.com/it/u=3564470921,849855233&fm=253&fmt=auto&app=138&f=JPEG?w=300&h=300'" />
        </div>
        <dl>
          <dt>手机配件 <span>人才自取</span></dt>
          <dd>有这方面经验的优先</dd>
        </dl>
        <div class="box">
          <p>浙江省</p>
          <p class="an"
             @click="jump">查看详情</p>
        </div>
      </li>
    </ul>
    <h1 class="title1">公司列表</h1>
    <ul class="content">
      <li @click="gsClick">
        <div class="img">
          <van-image width="100%"
                     height="100%"
                     fit="cover"
                     class="box1-img"
                     :src="'https://img0.baidu.com/it/u=873339910,2279000768&fm=253&fmt=auto&app=138&f=JPEG?w=749&h=500'" />
        </div>
        <dl>
          <dt>台州奕点网络科技有限公司</dt>
          <dd class="c-2">一家以软件开发为核心，从事企业数字化管理、物联网应用、移动办公解决方案的高新技术型科技开发公司。奕点科技拥有经验丰富的行业管理人才和专业扎实的开发与服务团队，扎根互联网行业数年，坚持“高质量、高效率、高性能”的服务宗旨，将累计的经验、擅长的技术与每一个企业自身的需求进行组合。始终背负着为客户提供最优服务的使命，持续发挥企业的社会效益并且得到广大客户的认可</dd>
        </dl>
      </li>
    </ul>
    <div class="week_foot">
      <div class="week_fwp">
        <div class="week_foot-about">
          <div class="week_foot-tit"><a title="关于我们">关于我们</a><i></i></div>
          <div class="week_foot-cont">
            <p class="week_foot-contact-item"></p>
            <div class="week_foot-contact-item">
              <p class="name">公司名称：</p>
              <p class="text">路桥区科技发展有限公司</p>
            </div>
            <div class="week_foot-contact-item">
              <p class="name">公司介绍：</p>
              <p class="text">台州市路桥区科技发展有限公司成立于2007年12月11日，注册地位于浙江省台州市路桥区珠光街201号，法定代表人为陈韬。经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；人力资源服务（不含职业中介活动、劳务派遣服务）；物业管理；住房租赁；非居住房地产租赁；太阳能发电技术服务；大数据服务(除依法须经批准的项目外，凭营业执照依法自主开展经营活动)。许可项目：劳务派遣服务；职业中介活动；建筑劳务分包(依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准)。台州市路桥区科技发展有限公司对外投资2家公司。</p>
            </div>
          </div>
        </div>
        <div class="week_foot-contact">
          <p class="week_foot-tit"><a title="联系我们">应用详情</a><i></i></p>
          <p class="week_foot-contact-item"></p>
          <div class="week_foot-contact-item">
            <p class="name">应用名称：</p>
            <p class="text">帮帮共富</p>
          </div>
          <div class="week_foot-contact-item">
            <p class="name">公司地址：</p>
            <p class="text">浙江省台州市路桥区珠光街201号</p>
          </div>
          <div class="week_foot-contact-item">
            <p class="name">应用详情：</p>
            <p class="text">帮助需要副业或者在家没事情干的人群增加额外收入</p>
          </div>
          <div class="week_foot-contact-item">
            <p class="name">服务信息：</p>
            <p class="text">保证任务的可信度，保护人的信息资料，有众多的公司资源</p>
          </div>
        </div>
      </div>
    </div>
    <div id="wk_ft">
      <div class="wk_footer"> Copyright 2022 路桥区科技发展有限公司&nbsp; 备案号：<a href="https://beian.miit.gov.cn/"
           target="_blank"
           title="浙ICP备2022018922号-1">
          浙ICP备2022018922号-1</a> <br>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  data () {
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 允许点击小圆点跳转
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false, // 手动切换之后继续自动轮播
        },
        //左右点击
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
        // loop: true,
        // loopAdditionalSlides: 101,
      },
    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    jump () {
      this.$router.push({
        path: '/content'
      })
    },
    rwClick () {
      this.$router.push({
        path: '/rw'
      })
    },
    rcClick () {
      this.$router.push({
        path: '/rc'
      })
    },
    gsClick () {
      this.$router.push({
        path: '/gsContent'
      })
    },
  },
}

</script>
<style scoped>
.lunbo {
  height: 500px;
  background: white;
  overflow: hidden;
  margin-top: 1px;
}

.swiper-container {
  height: calc(100% - 40px);
  width: 100%;
  margin: 20px 0;
  border-radius: 10px;
  overflow: hidden;
}

.swiper-container .box1-img {
  width: 70% !important;
  margin-left: 15%;
  border-radius: 15px;
  overflow: hidden;
}

.content {
  overflow: hidden;
  width: 100%;
  padding: 20px 15%;
  box-sizing: border-box;
  background: white;
}

.content li {
  width: calc(25% - 15px);
  margin-right: 20px;
  overflow: hidden;
  float: left;
  margin-bottom: 20px;
  cursor: pointer;
}

.content li:nth-child(4n) {
  margin-right: 0;
}

.content li .img {
  width: 100%;
  height: 150px;
}

.content li dl {
  padding-top: 10px;
}

.content li dl dt {
  font-size: 20px;
  font-weight: 600;
}

.content li dl dt span {
  font-size: 14px;
  font-weight: 400;
  color: #1890ff;
}

.content li dl dd {
  margin-top: 5px;
  font-size: 14px;
  color: #9c9c9c;
}

.content li .box {
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 14px;
  justify-content: space-between;
  margin-top: 10px;
}

.content li .box .an {
  background: #1890ff;
  color: white;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  cursor: pointer;
}

.title1 {
  background: white;
  padding: 0 15%;
  font-weight: 600;
  font-size: 40px;
  padding-top: 40px;
}
</style>
<style>
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 50px;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 50px;
}
</style>